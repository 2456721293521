import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import LoadingDots from 'components/ui/LoadingDots';
import Logo from 'components/icons/Logo';
import { getURL } from '@/utils/helpers';
import { Auth } from '@/components/auth/supabase/src/components/Auth';


import DashboardLoading from '@/components/app/DashboardLoading';
import { Shimmer } from '@/components/ui/LoadingDots/ShimmerCard';
import { Grid } from 'react-loader-spinner';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ThemeSupa } from '../lib/supatheme';

interface SignInProps {
  inviteTokenEmail: string;
}

const SignIn = ({ inviteTokenEmail }: SignInProps) => {
  const router = useRouter();
  const user = useUser();
  const supabaseClient = useSupabaseClient();
  const [captchaToken, setCaptchaToken] = useState<string>();
  
  useEffect(() => {
    if (user) {
      // check invite token
      const inviteToken = window.localStorage.getItem('inviteToken');
      if (inviteToken) {
        router.replace(`/router/?invite_token=${inviteToken}`);
        
      } else {
        router.replace('/router/');
      }
    } else if (user && router.query.redirect !== 'pricing') {
      router.replace('/app/');
    } else if (user && router.query.redirect === 'pricing') {
      router.replace('/pricing');
    }

    // get redirect query if exists
  }, [user]);

  useEffect(() => {
    // get token param in query which is i nvite_token query param
    const token  = router.query.invite_token ?? "" ;
    // save it to local storage
    if (token) {
      console.log("token", token);
      window.localStorage.setItem('inviteToken', token as string);
    }
  }, [router.query]);

 // Prefill email if invite token exists
 const [email, setEmail] = useState(inviteTokenEmail || '');


 
  function getRedirect() {
    if (router.query.redirect === 'pricing') {
      return `/pricing`;
    }
    return '/router';

    // supabaseClient.auth.getSession().then((s)=>s.data.session?.access_token)
    // check to see if user has a company
    // fetch("/api/user", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     user_id: user?.id,
    //   }),

    // })
    // .then((res) => res.json())

    // check to see if user has a company

    return '';
  }

  const captchaTokenRef = useRef<any>(null);

  const handleCaptchaVerify = (token: string) => {
    captchaTokenRef.current = token;
    console.log('captcha token', captchaTokenRef.current);

  };



  // Call the function


  if (!user)
    return (
      <div className="mt-10 flex justify-center height-screen-helper">
        <div className="flex flex-col justify-between max-w-lg p-3 m-auto w-80 ">
          <div className="flex justify-center pb-6 pt-2">
            <Logo width="64px" height="64px" />
          </div>
          <div className="flex flex-col  space-y-4">
            <Auth
              supabaseClient={supabaseClient}
              providers={['google','workos']}
              redirectTo={getURL() + getRedirect()}
              magicLink={true}
              view="sign_in"
              showLinks={true}
              theme="dark"
              additionalData={{
                captchaToken: captchaTokenRef.current,
                email: email ?? ''

              }}
              localization={{
                variables: {
                  sign_in: {
                    social_provider_text: '',
                    button_label: 'Sign in'
                  },
                  sign_up: {
                    social_provider_text: ''
                  },
                  forgotten_password: {
                    button_label: '',
                    link_text: ''
                  },
                  magic_link: {
                    button_label: 'Send magic link to sign in',
                    link_text: 'Send magic link to sign in'
                  },
                },
              }}
              appearance={{ theme: ThemeSupa }}
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className="w-full h-screen">
      <div className="flex items-center justify-center min-h-screen h-full">
        <div className="mt-64 w-full flex items-center justify-center">
          {/* <h2 className="text-lg mb-4 font-semibold">Conversations</h2> */}
          <Grid
            height={30}
            width={30}
            radius={5}
            color={'#8559F4'}
            ariaLabel="grid-loading"
            visible={true}
          />
        </div>
      </div>
    </div>
  );
};
export default SignIn;

export async function getServerSideProps(context: any) {
  const inviteToken = context.query.invite_token;

  // Implement the same logic you have in getInviteTokenData here
  let inviteTokenEmail = '';
  if (inviteToken) {
    // Fetch and process the invite token
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/decodeInvToken?inviteToken=${inviteToken}`);
      const data = await response.json();
      inviteTokenEmail = data.receiverEmail || '';
    } catch (error) {
      console.error('Failed to fetch invite token data', error);
      // Handle error or set inviteTokenEmail to a default/fallback value
    }
  }

  return {
    props: {
      inviteTokenEmail, // this will be passed to the page component as props
    },
  };
}



